.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.hyperlink {
    @import "./themes/generated/variables.base.scss";
    text-decoration: underline;
    cursor: pointer;
    color: $base-accent;
}