.container {
  display: flex;
  flex-direction: row !important;
  font-size:15px;
  max-width:300px;
}

.container > div {
  margin: 5px;
}

.errorStatusMessage{
  font-weight:bold;
  color:red;
}

.statusMessage {
  font-weight: bold;
  color:forestgreen;
}