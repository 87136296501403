@import "../../themes/generated/variables.base.scss";

.group-with-border .dx-form-group-content {
  margin-top:0 !important;
  padding-top:0 !important;
  border: 1px solid $base-border-color;
  padding: 10px !important;
}

.group-with-border .dx-form-group-caption {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-left: 0px;
  position: relative;
  top: 13px;
  left: 13px;
  background-color: white;
}

#emailForm .dx-field-item {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
