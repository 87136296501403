.attachment-type-title {
    padding: 10px;
}

.footer-buttons-container {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top:-15px;
}