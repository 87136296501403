.attachment-type-title {
    padding: 10px;
}

.tab-form-container-visible {
    height: calc(100% - 100px);
    display:block;
}

.tab-container-visible {
    height: 100%;
    display: block;
}
.tab-container-invisible {
    display: none;
}

.tab-scrolling{
    height: 100%;
    width: 100%;
}
