
.csr-summary-container {
    background-color: gray;
    width: 100%;
}
.csr-summary {
    display: flex;
    width:50%;
}
.csr-summary > div {
    color: white;
    margin: .1em;
    padding: .3em;
    flex: 1;
}
.csr-summary-repsidentified-red{
    color:red;
}
