@import "../../themes/generated/variables.base.scss";

.login-header {
    text-align: center;
    margin-bottom: 40px;

    .title {
        color: $base-accent;
        font-weight: bold;
        font-size: 30px;
        margin: 0px;
        margin-top: 20px;
    }
    .release {
        margin: 0px;
        margin-bottom: 20px;
    }

    .logo {
        width: 100%;
    }
}
