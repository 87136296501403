$base-accent: #3dbfd1;
$base-font-family: "Roboto", "RobotoFallback", "Noto Kufi Arabic", "Helvetica", "Arial", sans-serif;
$base-text-color: #fff;
$base-label-color: #fff;
$base-icon-color: rgba(255, 255, 255, 0.54);
$base-spin-icon-color: rgba(255, 255, 255, 0.54);
$base-bg: #363640;
$base-border-color: rgba(102, 194, 46, 0.5);
$base-success: #8bc34a;
$base-warning: #ffc107;
$base-danger: #f44336;
$base-hover-color: #fff;
$base-hover-bg: rgba(255, 255, 255, 0.05);
$base-focus-color: rgba(0, 0, 0, 0.87);
$base-focus-bg: rgba(255, 255, 255, 0.05);
$base-link-color: #3dbfd1;
$base-border-radius: 2px;
$button-normal-color: #fff;
$button-normal-bg: #3d2161;
$button-normal-hover-bg: #502b7f;
$button-normal-focused-bg: #502b7f;
$button-normal-active-bg: #8756c5;
$button-default-color: rgba(0, 0, 0, 0.87);
$button-default-bg: #3dbfd1;
$button-default-hover-bg: #60cbd9;
$button-default-focused-bg: #60cbd9;
$button-default-active-bg: #b0e5ec;
$button-danger-color: rgba(0, 0, 0, 0.87);
$button-danger-bg: #f44336;
$button-danger-hover-bg: #f6695f;
$button-danger-focused-bg: #f6695f;
$button-danger-active-bg: #f99b94;
$button-success-color: rgba(0, 0, 0, 0.87);
$button-success-bg: #8bc34a;
$button-success-hover-bg: #a0ce6b;
$button-success-focused-bg: #a0ce6b;
$button-success-active-bg: #bbdc95;
$list-group-color: rgba(255, 255, 255, 0.6);
$list-border-color: rgba(102, 194, 46, 0.5);
$list-group-header-bg: rgba(54, 54, 64, 0);
$list-normal-color: #fff;
$list-expanded-group-color: #3dbfd1;
$list-item-hover-bg: rgba(255, 255, 255, 0.05);
$list-item-selected-bg: rgba(110, 110, 128, 0.5);
$list-item-active-bg: #656577;
$fieldset-field-label-color: white;
$tabs-tab-color: rgba(255, 255, 255, 0.54);
$tabs-tab-selected-color: #3dbfd1;
$tabs-selected-tab-bottom-border-color: #3dbfd1;
$tabs-tab-bg: #2f2f38;
$tabs-hovered-tab-bg-color: #464653;
$scrollable-scroll-bg: rgba(112, 112, 133, 0.7);
$badge-color: rgba(0, 0, 0, 0.87);
$badge-bg: #3dbfd1;
$navbar-tab-color: #fff;
$navbar-tab-selected-color: #fff;
$navbar-tab-bg: #2f2f38;
$navbar-tab-selected-bg: #363640;
$overlay-content-bg: #363640;
$overlay-shader-bg: rgba(0, 0, 0, 0.61);
$textbox-search-icon-color: rgba(255, 255, 255, 0.54);
$texteditor-color: #fff;
$texteditor-placeholder-color: gray;
$texteditor-bg: rgba(255, 255, 255, 0.04);
$texteditor-focused-bg: rgba(255, 255, 255, 0.04);
$texteditor-border-color: rgba(255, 255, 255, 0.42);
$texteditor-hover-border-color: #fff;
$texteditor-focused-border-color: #3dbfd1;
$texteditor-hover-bg: rgba(255, 255, 255, 0.07);
$load-indicator-segment-bg-color: #3dbfd1;
$treeview-focused-bg: rgba(255, 255, 255, 0.05);
