
.address-autocomplete-container {
    z-index: 1000;
    position: absolute;
    background-color: white;
    width: 100%;
}

.address-autocomplete-container-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.address-autocomplete-container-address-list {
    border: solid;
    border-top: none;
    border-bottom: solid 0.5px;
}

.hideVerifiedAddressIcon {
  display: none,
}

.showVerifiedAddressIcon {
  display:flex;
}