.title {
    background-color: white;
    padding: 10px;
    font-size: medium;
}

.copyDialogButtons {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top:10px;
}
