
.year-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}

.committee-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.committee-item {
    margin-left:5px;
}

#ref-date-container > .dx-field > .dx-field-label, #ref-date-container > .dx-field > .dx-field-value {
    width: 15%;
    min-width: 100px;
}

#ref-date-container > .dx-field-value:not(.dx-widget) > .dx-button, .dx-field-value:not(.dx-widget) > .dx-checkbox, .dx-field-value:not(.dx-widget) > .dx-switch {
    float: left;
    margin-left: 10px;
}

.dx-master-detail-cell {
  background-color: cornsilk !important;
  padding: 10px !important;
}


