$base-accent: #3dbfd1;
$base-font-family: "Roboto", "RobotoFallback", "Noto Kufi Arabic", "Helvetica", "Arial", sans-serif;
$base-text-color: #3d2161;
$base-label-color: #3d2161;
$base-icon-color: rgba(61, 33, 97, 0.54);
$base-spin-icon-color: rgba(61, 33, 97, 0.54);
$base-bg: #fff;
$base-border-color: rgba(102, 194, 46, 0.5);
$base-success: #8bc34a;
$base-warning: #ffc107;
$base-danger: #f44336;
$base-hover-color: #3d2161;
$base-hover-bg: rgba(0, 0, 0, 0.04);
$base-focus-color: #fff;
$base-focus-bg: rgba(0, 0, 0, 0.04);
$base-link-color: #3dbfd1;
$base-border-radius: 2px;
$button-normal-color: #3d2161;
$button-normal-bg: #fff;
$button-normal-hover-bg: #ebebeb;
$button-normal-focused-bg: #ebebeb;
$button-normal-active-bg: #b3b3b3;
$button-default-color: #fff;
$button-default-bg: #3dbfd1;
$button-default-hover-bg: #2ba6b7;
$button-default-focused-bg: #2ba6b7;
$button-default-active-bg: #217d8a;
$button-danger-color: #fff;
$button-danger-bg: #f44336;
$button-danger-hover-bg: #f11d0d;
$button-danger-focused-bg: #f11d0d;
$button-danger-active-bg: #bc160a;
$button-success-color: #fff;
$button-success-bg: #8bc34a;
$button-success-hover-bg: #75a938;
$button-success-focused-bg: #75a938;
$button-success-active-bg: #587f2a;
$list-group-color: #3d2161;
$list-border-color: rgba(102, 194, 46, 0.5);
$list-group-header-bg: rgba(255, 255, 255, 0);
$list-normal-color: #3d2161;
$list-expanded-group-color: #3dbfd1;
$list-item-hover-bg: rgba(0, 0, 0, 0.04);
$list-item-selected-bg: rgba(0, 0, 0, 0.12);
$list-item-active-bg: #cccccc;
$fieldset-field-label-color: #b293da;
$button-group-normal-selected-color: #3d2161;
$button-group-normal-selected-bg: rgba(0, 0, 0, 0.18);
$button-group-default-selected-color: #3dbfd1;
$button-group-default-selected-bg: rgba(61, 191, 209, 0.22);
$button-group-danger-selected-color: #f44336;
$button-group-danger-selected-bg: rgba(244, 67, 54, 0.22);
$button-group-success-selected-color: #8bc34a;
$button-group-success-selected-bg: rgba(139, 195, 74, 0.22);
$scrollable-scroll-bg: rgba(191, 191, 191, 0.7);
$switch-bg: #9e9e9e;
$switch-handle-off-bg: #fafafa;
$switch-on-bg: rgba(61, 191, 209, 0.5);
$switch-handle-on-bg: #3dbfd1;
$tabs-tab-color: rgba(61, 33, 97, 0.54);
$tabs-tab-selected-color: #3dbfd1;
$tabs-selected-tab-bottom-border-color: #3dbfd1;
$tabs-tab-bg: #f7f7f7;
$tabs-hovered-tab-bg-color: #ededed;
$badge-color: #fff;
$badge-bg: #3dbfd1;
$navbar-tab-color: #3d2161;
$navbar-tab-selected-color: #3d2161;
$navbar-tab-bg: #f7f7f7;
$navbar-tab-selected-bg: #fff;
$overlay-content-bg: #fff;
$overlay-shader-bg: rgba(0, 0, 0, 0.32);
$textbox-search-icon-color: rgba(61, 33, 97, 0.54);
$texteditor-color: #3d2161;
$texteditor-placeholder-color: #d2c0ea;
$texteditor-bg: rgba(61, 33, 97, 0.04);
$texteditor-focused-bg: rgba(61, 33, 97, 0.04);
$texteditor-border-color: rgba(61, 33, 97, 0.42);
$texteditor-hover-border-color: #3d2161;
$texteditor-focused-border-color: #3dbfd1;
$texteditor-hover-bg: rgba(61, 33, 97, 0.07);
$dropdowneditor-icon-active-color: rgba(61, 33, 97, 0.54);
$calendar-header-color: #a27cd2;
$calendar-cell-other-color: #ba9ede;
$calendar-cell-selected-bg: #3dbfd1;
$calendar-shevron-icon-color: #3dbfd1;
$load-indicator-segment-bg-color: #3dbfd1;
$treeview-focused-bg: rgba(0, 0, 0, 0.04);
$menu-color: #b293da;
$menu-item-selected-bg: white;
$selectbox-list-bg: #fff;
$toolbar-bg: #fff;
$popup-title-bg: transparent;
$tileview-color: #3d2161;
$toast-color: rgba(255, 255, 255, 0.87);
$toast-info-bg: rgba(0, 0, 0, 0.87);
$toast-warning-bg: #ffc107;
$toast-error-bg: #f44336;
$toast-success-bg: #8bc34a;
$progressbar-bg: rgba(61, 191, 209, 0.3);
$progressbar-range-bg: #3dbfd1;
$progressbar-label-color: #3dbfd1;
$tooltip-color: #fff;
$tooltip-bg: #616161;
$material-slider-bar-bg: rgba(61, 191, 209, 0.24);
$material-slider-bg: #3dbfd1;
$material-slider-tooltip-color: #fff;
$gallery-indicator-bg: #fff;
$gallery-indicator-item-selected-bg: #3dbfd1;
$gallery-indicator-focused-bg: #3dbfd1;
$gallery-navbutton-hover-color: #3dbfd1;
$gallery-navbutton-active-color: #3dbfd1;
$loadpanel-content-bg: #fff;
$tagbox-tag-color: rgba(61, 33, 97, 0.6);
$tagbox-tag-bg: #e0e0e0;
$tagbox-tag-active-color: #3d2161;
$tagbox-tag-button-remove-bg: rgba(0, 0, 0, 0.36);
$accordion-title-color: #3d2161;
$accordion-item-border-color: rgba(102, 194, 46, 0.5);
$accordion-icon-active-color: #3d2161;
$colorbox-overlay-bg: #fff;
$datagrid-columnchooser-item-color: rgba(61, 33, 97, 0.54);
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-border-color: rgba(61, 191, 209, 0.5);
$datagrid-selection-bg: rgba(61, 191, 209, 0.04);
$datagrid-row-selected-border-color: rgba(102, 194, 46, 0.5);
$datagrid-row-selected-color: #3d2161;
$datagrid-row-focused-color: #fff;
$datagrid-row-focused-bg: #64ccda;
$datagrid-menu-icon-color: rgba(145, 100, 202, 0.54);
$datagrid-cell-modified-border-color: rgba(139, 195, 74, 0.32);
$datagrid-row-invalid-faded-border-color: rgba(244, 67, 54, 0.32);
$datagrid-group-row-color: rgba(61, 33, 97, 0.54);
$datagrid-group-row-bg: white;
$datagrid-search-color: #fff;
$datagrid-search-bg: #3dbfd1;
$datagrid-row-error-color: #fff;
$datagrid-row-error-bg: #f88e86;
$datagrid-focused-border-color: #64ccda;
$datagrid-editor-bg: rgba(255, 255, 255, 0);
$datagrid-row-alternation-bg: whitesmoke;
$datagrid-summary-color: rgba(61, 33, 97, 0.7);
$pager-page-selected-color: #fff;
$pager-page-selected-bg: #3dbfd1;
$pivotgrid-area-color: rgba(61, 33, 97, 0.54);
$pivotgrid-totalcolor: rgba(221, 221, 221, 0.2);
$pivotgrid-grandtotalcolor: rgba(0, 0, 0, 0.04);
$pivotgrid-field-area-text-color: rgba(0, 0, 0, 0.3);
$fileuploader-filename-color: #3d2161;
$fileuploader-falename-status-color: #a27cd2;
$fileuploader-border-color: rgba(102, 194, 46, 0.5);
$scheduler-base-border-color: rgba(102, 194, 46, 0.5);
$scheduler-accent-border-color: rgba(102, 194, 46, 0.5);
$scheduler-appointment-text-color: #fff;
$scheduler-appointment-base-color: #3dbfd1;
$scheduler-appointment-start-color: rgba(0, 0, 0, 0.3);
$scheduler-workspace-focused-cell-color: rgba(61, 191, 209, 0.12);
$scheduler-first-month-border-color: rgba(102, 194, 46, 0.5);
$scheduler-current-time-cell-color: #3dbfd1;
$form-field-item-color: #b293da;
$form-group-border-color: rgba(102, 194, 46, 0.5);
$filterbuilder-text-color: #3d2161;
$filterbuilder-text-focus-color: #fff;
$filterbuilder-plus-icon-color: rgba(139, 195, 74, 0.7);
$filterbuilder-remove-icon-color: rgba(244, 67, 54, 0.7);
$filterbuilder-group-operation-color: rgba(244, 67, 54, 0.3);
$filterbuilder-item-field-color: rgba(61, 191, 209, 0.3);
$filterbuilder-item-operator-color: rgba(139, 195, 74, 0.3);
$filterbuilder-item-value-color: rgba(102, 194, 46, 0.5);
$drawer-shader-background-color: rgba(0, 0, 0, 0.5);
